<template>
  <div>
    <HandoverProcessing
      v-if="order && order.id"
      :order="order"
      @onFinished="getProcessing"
    />
    <HandoverWaitList v-else @onStarted="getProcessing" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Handover",
  components: {
    HandoverWaitList: () => import("@/components/dc/HandoverWaitList"),
    HandoverProcessing: () => import("@/components/dc/HandoverProcessing"),
  },
  data: () => ({
    order: {},
  }),
  mounted() {
    this.getProcessing();
  },
  methods: {
    getProcessing() {
      httpClient
        .post("/dc/v1/handover-get-order-processing")
        .then(({ data }) => {
          this.order = { ...data };
        });
    },
  },
};
</script>
